<template>
  <div ref="businessSettlementSetting" class="businessSettlementSetting">
    <div class="tabs">
      <el-tabs v-model="form.channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="area">
      <el-tabs v-model="form.businessType" @tab-click="handleClick">
        <el-tab-pane label="定制客运" name="1">
          <div class="explain">
            <header><i class="el-icon-warning"></i> 数据说明</header>
            <ol>
              <li>经营结算默认不结算退/改手续费</li>
            </ol>
          </div>
          <div class="box">
            <header>
              <span>经营结算</span>
              <p>修改保存后下个结算周期生效。</p>
            </header>
            <div class="inner">
              <p>结算订单是否包含取消接驾订单​</p>
              <el-radio-group v-model="radio" @input="cancelOrder">
                <el-radio :label="0">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="box">
            <header>
              <span>服务费设置</span>
              <p>
                服务费比例用于经营结算中服务费的计算。默认比例为0%，修改保存后下个结算周期生效。
              </p>
            </header>
            <div class="form-tab">
              <span>线路：</span>
              <el-select
                v-model="form.lineId"
                placeholder="请选择线路"
                filterable
                clearable
              >
                <el-option
                  v-for="item in lineList"
                  :key="item.id"
                  :label="item.lineName"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-button type="primary" size="small" @click="queryFun"
                >查询</el-button
              >
            </div>
            <div class="table">
              <Table
                :table-data="tableData"
                :title-name="titleName"
                :operation="true"
                operation-width="80"
                :table-height="TableHeight"
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="listFind('编辑')"
                    type="text"
                    size="small"
                    sort="primary"
                    @click="editFun(scope.scopeRow)"
                    >编辑</el-button
                  >
                </template>
              </Table>
            </div>
            <div ref="pagination" class="pagination">
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-size="pageSize"
                :current-page="currentPage"
                @size-change="onSizeChange1"
                @current-change="onCurrentChange1"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="汽车票" name="2">
          <div class="explain">
            <header><i class="el-icon-warning"></i> 数据说明</header>
            <ol>
              <li>经营结算默认不结算退/改手续费</li>
            </ol>
          </div>
          <div class="form-tab">
            <span>所属车站：</span>
            <el-select
              v-model="companyId"
              clearable
              placeholder="请选择所属车站"
            >
              <el-option
                v-for="role in stationList"
                :key="role.tCompanyId"
                :label="role.oCompanyName"
                :value="role.tCompanyId"
              ></el-option>
            </el-select>
            <el-button type="primary" size="small" @click="queryFun2"
              >查询</el-button
            >
          </div>
          <div style="margin-top: 16px">
            <Table
              :table-data="tableData"
              :title-name="titleName2"
              :operation="true"
              operation-width="80"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="listFind('编辑2')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="editFun(scope.scopeRow)"
                  >编辑</el-button
                >
              </template>
            </Table>
          </div>
          <div ref="pagination" class="pagination">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-size="pageSize"
              :current-page="currentPage"
              @size-change="onSizeChange2"
              @current-change="onCurrentChange2"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      v-if="showPopup"
      :visible.sync="showPopup"
      title="编辑"
      :close-on-click-modal="false"
      width="400px"
    >
      <div class="popup">
        <el-form
          ref="form"
          :model="ruleForm"
          :rules="rules"
          label-position="left"
          label-width="100px"
        >
          <el-form-item label="服务费比例：" prop="value">
            <el-input v-model.number="ruleForm.value"></el-input>%
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showPopup = false">取消</el-button>
        <el-button type="primary" size="small" @click="saveFun">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCurrentUserChannelInfoAPI,
  queryServiceFeeConfigurationPage,
  updateReception,
  updateServiceFeeConfiguration,
  getStationList,
  scheduleLineAssociationListAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      channelList: [],
      radio: 1,
      tableData: [],
      TableHeight: 0,
      titleName: [
        {
          title: "车站",
          props: "companyName",
        },
        {
          title: "线路名称",
          props: "lineName",
        },
        {
          title: "起点站",
          props: "startStation",
        },
        {
          title: "终点站",
          props: "endStation",
        },
        {
          title: "所属主线路",
          props: "mainLineName",
        },
        {
          title: "服务费比例",
          props: "serviceFeeRate",
          SpecialJudgment: (res) => (res !== null ? res * 100 + "%" : "0%"),
        },
      ],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      companyId: null,
      lineList: [],
      stationList: [],
      titleName2: [
        {
          title: "车站",
          props: "companyName",
        },
        {
          title: "服务费比例",
          props: "serviceFeeRate",
          SpecialJudgment: (res) => res * 100 + "%",
        },
      ],
      showPopup: false,
      form: {
        businessType: "1",
        channelNo: null,
        currentPage: 1,
        pageSize: 10,
        companyId: null,
        lineId: null,
      },
      ruleForm: {
        value: 0,
      },
      rules: {
        value: {
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (!value) callback("需填写1-100的整数");
            else if (/(^[1-9][\d]?|100)$/.test(value)) callback();
            else callback("需填写1-100的整数");
          },
        },
      },
      id: null,
    };
  },
  mounted() {
    this.getQueryChannelList();
    this.queryStationList();
    this.queryLine();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
          this.form.channelNo = res.data[0]?.channelNo;
          this.radio = res.data[0].intercityCancelPickUpState;
          this.renderData1();
        }
      });
    },
    //获取汽车站列表
    queryStationList() {
      getStationList().then((res) => {
        this.stationList = res.data;
      });
    },
    queryFun() {
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.renderData1();
    },
    // 表格数据渲染
    renderData1() {
      let obj = this.deepClone(this.form);
      obj.businessType = parseInt(obj.businessType);
      queryServiceFeeConfigurationPage(obj).then((res) => {
        this.tableData = res.data.list || [];
        this.total = res.data.total;
      });
    },
    queryLine() {
      const companyId = sessionStorage.getItem("companyId");
      scheduleLineAssociationListAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.lineList = res.data.filter(
            (i) => i.channelNo == this.form.channelNo
          );
        }
      });
    },
    handleClick() {
      this.total = 0;
      this.tableData = [];
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.form.companyId = null;
      this.form.lineId = null;
      this.queryLine();
      this.radio = this.channelList.find(
        (i) => i.channelNo == this.form.channelNo
      ).intercityCancelPickUpState;
      if (this.form.businessType == "1") {
        this.renderData1();
      } else {
        this.renderData2();
      }
    },
    cancelOrder() {
      const data = {
        intercityCancelPickUpState: this.radio,
        channelNo: this.form.channelNo,
      };
      updateReception(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("修改成功");
          getCurrentUserChannelInfoAPI().then((res) => {
            if (res.code == "SUCCESS") {
              this.channelList = res.data;
            }
          });
        }
      });
    },
    editFun(row) {
      this.showPopup = true;
      this.ruleForm.value = row.serviceFeeRate * 100;
      if (this.form.businessType == "1") {
        this.id = row.lineId;
      } else {
        this.id = row.companyId;
      }
    },
    onCurrentChange1(page) {
      this.form.currentPage = page;
      this.renderData1();
    },
    onSizeChange1(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.renderData1();
    },
    onCurrentChange2(page) {
      this.form.currentPage = page;
      this.renderData2();
    },
    onSizeChange2(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.renderData2();
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.businessSettlementSetting.clientHeight;
      this.TableHeight = wholeHeight - 485 + "px";
    },
    queryFun2() {
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.renderData2();
    },
    // 表格数据渲染
    renderData2() {
      this.form.companyId = this.companyId;
      let obj = this.deepClone(this.form);
      obj.businessType = parseInt(obj.businessType);
      queryServiceFeeConfigurationPage(obj).then((res) => {
        this.tableData = res.data.list || [];
        this.total = res.data.total;
      });
    },
    saveFun() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let obj = {
            serviceFeeRate: this.ruleForm.value / 100,
            businessType: parseInt(this.form.businessType),
            channelNo: this.form.channelNo,
          };
          if (obj.businessType == "1") {
            obj.lineId = this.id;
          } else {
            obj.companyId = this.id;
          }
          updateServiceFeeConfiguration(obj).then((res) => {
            if (res.code == 1000) {
              this.$message.success("修改成功");
              this.showPopup = false;
              if (obj.businessType == "1") {
                this.renderData1();
              } else {
                this.renderData2();
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.businessSettlementSetting {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  .area {
    padding: 0 16px;
    flex: 1;
  }
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .box {
    header {
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;
      span {
        font-size: 20px;
        font-weight: bold;
      }
      p {
        color: #7e7e7e;
        margin-left: 20px;
      }
    }
    .inner {
      margin: 16px 0;
      display: flex;
      p {
        margin: 0 30px 0 20px;
      }
    }
    .table {
      margin-top: 16px;
    }
  }
  .form-tab {
    background-color: #f7f8f9;
    padding: 16px;
    display: flex;
    align-items: center;
    .el-button {
      margin-left: 20px;
    }
  }
  .popup {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    .el-input {
      width: 100px;
      margin-right: 10px;
    }
  }
}
</style>
